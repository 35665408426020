<template>
  <div class="aboutUs">
    <div>
      <template v-if="language=='zh'">
        <MyImg :url='src'></MyImg>
      </template>
      <template v-if="language=='TW'">
        <MyImg :url='srcTW'></MyImg>
      </template>
      <template v-if="language=='En'">
        <MyImg :url='srcEn'></MyImg>
      </template>
      <div class="content2 hidden-xs-only " >
        <div >
          <template v-if="language=='zh'">
            <img src="@/assets/aboutCN.png" style="width: 100vw;position: relative;left: -20vw">
          </template>
          <template v-if="language=='TW'">
            <img src="@/assets/aboutTW.png" style="width: 100vw;position: relative;left: -20vw">
          </template>
          <template v-if="language=='En'">
            <img src="@/assets/aboutEn.png" style="width: 100vw;position: relative;left: -20vw">
          </template>

<!--          <template v-if="language=='zh'">-->
<!--            <img src="@/assets/aboutus.png" style="width: 100vw;position: relative;left: -20vw">-->
<!--          </template>-->
<!--          <template v-if="language=='TW'">-->
<!--            <img src="@/assets/aboutusTW.png" style="width: 100vw;position: relative;left: -20vw">-->
<!--          </template>-->
<!--          <template v-if="language=='En'">-->
<!--            <img src="@/assets/aboutusTW.png" style="width: 100vw;position: relative;left: -20vw">-->
<!--          </template>-->

        </div>
<!--        <div class="title">-->
<!--          <div class="english-yellow"> {{$t('aboutUs.OverseasStudy')}}</div>-->
<!--          <div class="service-con">-->
<!--            <div class="servicetop"> {{$t('aboutUs.一站式留学')}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="yizhanshi">-->
<!--          <div class="img">-->
<!--            <el-image-->
<!--                :src="srcone"-->
<!--                style="height: 256px; width: 400px"-->
<!--                fit="cover"-->
<!--            ></el-image>-->
<!--          </div>-->
<!--          <div class="text">-->
<!--          {{$t('aboutUs.致力于提供香港')}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="liuxuesheng">-->
<!--          <div class="div">-->
<!--            <div class="img">-->
<!--              <img-->
<!--                  src="../../assets/image/douhao.png"-->
<!--                  style="height: 53px; width: 71px"-->
<!--                  alt=""-->
<!--              />-->
<!--              <span>{{$t('aboutUs.留学生温暖生活社区')}}</span>-->
<!--            </div>-->
<!--            <div class="text">{{$t('aboutUs.ivingcommunity')}}</div>-->
<!--            <div class="text2">{{$t('aboutUs.汇生会成立于xxxx年')}}</div>-->
<!--            <div class="text3">-->
<!--              {{$t('aboutUs.同时我们致力于提供周全的学生生活服务')}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <el-image-->
<!--                :src="srctwo"-->
<!--                style="height: 380px; width: 445px"-->
<!--                fit="cover"-->
<!--            ></el-image>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="card">-->
<!--          <div class="title2">-->
<!--            {{$t('aboutUs.是香港领先拥有留学')}}-->
<!--          </div>-->
<!--          <div class="div2">-->
<!--            <div>-->
<!--              <div>{{$t('aboutUs.录取成功率')}}</div>-->
<!--              <div>90%</div>-->
<!--            </div>-->
<!--            <div>-->
<!--              <div>{{$t('aboutUs.公寓累积服务人数过千位学生')}}</div>-->
<!--              <div>1000+</div>-->
<!--            </div>-->
<!--            <div>-->
<!--              <div>{{$t('aboutUs.超过5年高端留学申请经验')}}</div>-->
<!--              <div>5年+</div>-->
<!--            </div>-->
<!--            <div>-->
<!--              <div>{{$t('aboutUs.专业顾问团队')}}</div>-->
<!--              <div></div>-->
<!--              <div>{{$t('aboutUs.括名校导师')}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="div3">-->
<!--          <div class="img">-->
<!--            <img-->
<!--                src="../../assets/image/douhao.png"-->
<!--                style="height: 53px; width: 71px"-->
<!--                alt=""-->
<!--            />-->
<!--            <span>{{$t('aboutUs.为何需要升学顾问')}}</span>-->
<!--          </div>-->
<!--          <div class="text">{{$t('aboutUs.Whydo')}}</div>-->
<!--          <div class="text2">{{$t('aboutUs.省时')}}</div>-->
<!--          <div class="text3">-->
<!--            {{$t('aboutUs.到外地留学并不是填好表格送出就完事了')}}-->
<!--          </div>-->
<!--          <div class="list">-->
<!--            <div class="card2">-->
<!--              <div>01</div>-->
<!--              <div>-->
<!--                {{$t('aboutUs.升学顾问能从每个学校的特点')}}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card2">-->
<!--              <div>02</div>-->
<!--              <div>-->
<!--                {{$t('aboutUs.在背景提升方面')}}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card2">-->
<!--              <div>03</div>-->
<!--              <div>-->
<!--               {{$t('aboutUs.往往留学文书材料是学生联系我们的主要原因')}}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="title">
          <div class="english-yellow"> {{$t('aboutUs.contactus')}}</div>
          <div class="service-con">
            <div class="servicetop2"> {{$t('aboutUs.联系我们')}}</div>
          </div>
        </div>
        <div>
          <div id="container"></div>
        </div>
        <div class="lianxiwomen">
          <div>
            <div style="color: #333; font-size: 20px; font-weight: 600">
              {{$t('aboutUs.联系我们')}}
            </div>
            <div class="erweima">
              <div>
                <el-image
                    :src="$store.state.baseUrl+$store.state.info.wxPublicUrl"
                    style="height: 158px; width: 158px;"
                    fit="cover"
                ></el-image>
              </div>
              <div style="margin-left:20px">
                <div>{{$t('aboutUs.联系我们')}}：{{$store.state.info.phone}}</div>
                <div>{{$t('aboutUs.客服热线')}}：{{$store.state.info.hotline}}</div>
<!--                <div>{{$t('aboutUs.微信公众号')}}：{{$store.state.info.wxPublic}}</div>-->
                <div>{{$t('aboutUs.联系地址')}}：{{$store.state.info.address}}</div>
              </div>
            </div>
          </div>
          <div class="zaixianliuyan">
            <div class="text" style="">{{$t('aboutUs.在线留言')}}</div>
            <div class="liuyan">
              <p>{{$t('aboutUs.姓名')}}</p>
              <input class="input" v-model="onlinequery.name"/>
            </div>
            <div class="liuyan">
              <p>{{$t('aboutUs.联系方式')}}</p>
              <input class="input" v-model="onlinequery.phone"/>
            </div>
            <div class="liuyan">
              <p>{{$t('aboutUs.邮箱')}}</p>
              <input class="input" v-model="onlinequery.email"/>
            </div>
            <div class="liuyan">
              <p>{{$t('aboutUs.内容')}}</p>
              <textarea class="input2" rows="8" v-model="onlinequery.content"></textarea>
            </div>
            <div class="btn" @click="onlinesubmit">{{$t('aboutUs.提交')}}</div>
          </div>
        </div>
      </div>
      <!-- 移动端 -->
      <div class="hidden-sm-and-up content-sm">
        <template v-if="language=='zh'">
          <img src="../../assets/about-smCN.png" style="width: 335px">
        </template>
        <template v-if="language=='TW'">
          <img src="../../assets/about-smTW.png" style="width: 335px">
        </template>
        <template v-if="language=='En'">
          <img src="../../assets/about-smEn.png" style="width: 335px">
        </template>
<!--        <div class="title">-->
<!--          <div class="english-yellow"> {{$t('aboutUs.OverseasStudy')}}</div>-->
<!--          <div class="service-con">-->
<!--            <div class="servicetop"> {{$t('aboutUs.一站式留学')}}</div>-->
<!--            <div class="line2"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="yizhanshi">-->
<!--            <div class="img">-->
<!--              <el-image-->
<!--                  :src="srcone"-->
<!--                  style="height: 197PX; width: 305PX"-->
<!--                  fit="cover"-->
<!--              ></el-image>-->
<!--              &lt;!&ndash; <div class="beijing"></div> &ndash;&gt;-->
<!--            </div>-->
<!--            <div class="text">-->
<!--              {{$t('aboutUs.致力于提供香港')}}-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="liuxuesheng">-->
<!--          <div class="div">-->
<!--            <div class="img">-->
<!--              <img-->
<!--                  src="../../assets/image/douhao.png"-->
<!--                  style="height: 31PX; width: 42PXpx"-->
<!--                  alt=""-->
<!--              />-->
<!--              <span>{{$t('aboutUs.留学生温暖生活社区')}}</span>-->
<!--            </div>-->
<!--            <div class="text">{{$t('aboutUs.ivingcommunity')}}</div>-->
<!--            <div class="text2">{{$t('aboutUs.汇生会成立于xxxx年')}}</div>-->
<!--            <div class="text3">-->
<!--              {{$t('aboutUs.同时我们致力于提供周全的学生生活服务')}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="liuxueshengimg">-->
<!--              <el-image-->
<!--                  :src="srctwo"-->
<!--                  style="height: 197PX; width: 305PX"-->
<!--                  fit="cover"-->
<!--              ></el-image>-->
<!--              <div class="beijing"></div>-->
<!--          </div>-->
<!--          <div>-->
<!--          <el-image-->
<!--                :src="srcthree"-->
<!--                style="height:176PX; width:335PX"-->
<!--                fit="cover"-->
<!--          ></el-image>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="liuxuesheng">-->
<!--          <div class="div">-->
<!--            <div class="img">-->
<!--              <img-->
<!--                  src="../../assets/image/douhao.png"-->
<!--                  style="height: 31PX; width: 42PXpx"-->
<!--                  alt=""-->
<!--              />-->
<!--              <span>{{$t('aboutUs.为何需要升学顾问')}}</span>-->
<!--            </div>-->
<!--            <div class="text">{{$t('aboutUs.Whydo')}}</div>-->
<!--            <div class="text2">{{$t('aboutUs.省时')}}</div>-->
<!--            <div class="text3">-->
<!--              {{$t('aboutUs.到外地留学并不是填好表格送出就完事了')}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img-->
<!--                src="../../assets/phone/aboutus.png"-->
<!--                style="height:830PX; width:335PX;margin:16PX 0;"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
        <div class="title">
          <div class="english-yellow"> {{$t('aboutUs.contactus')}}</div>
          <div class="service-con">
            <div class="servicetop"> {{$t('aboutUs.联系我们')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div>
          <div id="container2"></div>
        </div>
        <div class="lianxiwomen-sm">
          <div>
            <div style="color: #333; font-size: 14PX; font-weight: 600">
              {{$t('aboutUs.联系我们')}}
            </div>
            <div class="erweima">
              <div>
                <img
                    :src="$store.state.baseUrl+$store.state.info.wxPublicUrl"
                    style="height:91PX; width:91PX;"
                />
              </div>
              <div>
                <div>{{$t('aboutUs.联系电话')}}：{{$store.state.info.phone}}</div>
                <div>{{$t('aboutUs.客服热线')}}：{{$store.state.info.hotline}}</div>
<!--                <div>{{$t('aboutUs.微信公众号')}}：{{$store.state.info.wxPublic}}</div>-->
                <div>{{$t('aboutUs.联系地址')}}：{{$store.state.info.address}}</div>
              </div>
            </div>
          </div>
          <div class="zaixianliuyan">
            <div class="text" style="">{{$t('aboutUs.在线留言')}}</div>
            <div class="liuyan">
              <p>{{$t('aboutUs.姓名')}}</p>
              <input class="input" v-model="onlinequery.name"/>
            </div>
            <div class="liuyan">
              <p>{{$t('aboutUs.联系方式')}}</p>
              <input class="input" v-model="onlinequery.phone"/>
            </div>
            <div class="liuyan">
              <p>{{$t('aboutUs.邮箱')}}</p>
              <input class="input" v-model="onlinequery.email"/>
            </div>
            <div class="liuyan">
              <p>{{$t('aboutUs.内容')}}</p>
              <textarea class="input2" rows="8" v-model="onlinequery.content"></textarea>
            </div>
            <div class="btn" @click="onlinesubmit">{{$t('aboutUs.提交')}}</div>
          </div>
        </div>
      </div>
    </div>
    <Mydialog ref="icon" @submit="submitchange"></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import MyImg from "@/components/myimage";
import AMapLoader from "@amap/amap-jsapi-loader";
import "element-ui/lib/theme-chalk/display.css";
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import {addMessageRecord} from '@/api/index.js'

window._AMapSecurityConfig = {
  securityJsCode: "你的安全密钥",
};
export default {
  components: {
    MyImg,
    Mydialog,
    Myicon
  },
  data() {
    return {
      src:require('@/assets/image/aboutus.png'),
      srcTW:require('@/assets/image/aboutusTW.png'),
      srcEn:require('@/assets/image/aboutusEn.png'),
      map: null,
      form: {},
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      srcone:require('@/assets/image/yzstop.png'),
      srctwo:require('@/assets/image/yzscon.png'),
      srcthree:require('@/assets/image/yzsbot.png'),
      onlinequery:{
        recordType:1,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:6,	//必选	String	来源位置1:首页2:背景提升3香港留学4院校入口5学生公寓6关于我们7成功案例8港漂干货9名校直通车:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//	必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'',//	必选	String	性别(recordType=2/3)
        email:'',//	必选	String	邮箱
      },
      language:'zh'
    };
  },
  mounted() {
    this.language=localStorage.getItem('language')
    const that = this;
    that.initMap();
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.documentElement.clientWidth; //实时宽度
    //     window.screenHeight = document.documentElement.clientHeight; //实时高度
    //     console.log(this.screenWidth, this.screenHeight);
    //     that.screenWidth = window.screenWidth;
    //     that.screenHeight = window.screenHeight;
    //   })();
    // };
    window.addEventListener("resize", () => {
       that.$nextTick(() => {
        that.$forceUpdate()
      });
    });
  },
  methods: {
    onlinesubmit(){
      if(!this.onlinequery.phone){
        this.$message.error(this.$t('aboutUs.请填写联系方式'))
        return
      }
      addMessageRecord(this.onlinequery).then(()=>{
        this.$message.success(this.$t('aboutUs.提交成功'))
      })
    },
    //在线咨询（关于我们）
    submitchange(e){
        e.category=6
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('aboutUs.已提交'))
      })
    },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    initMap() {
      AMapLoader.load({
        key: "41b20a07a7c730fe4ff62d72fa3ea7b1", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              //设置地图容器id
              viewMode: "2D", //是否为3D地图模式
              zoom:17, //初始化地图级别
              center: [114.117975,22.36578], //初始化地图中心点位置
            });
             this.map = new AMap.Map("container2", {
              //设置地图容器id
              viewMode: "2D", //是否为3D地图模式
              zoom: 17, //初始化地图级别
              center: [114.117975,22.36578], //初始化地图中心点位置
            });
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },
};
</script>

<style lang="scss">
.aboutUs {
  #container {
    width: 100%;
    height: 440px;
    border-radius: 12px;
  }
#container2 {
    width: 100%;
    height: 300PX;
    border-radius: 12px;
  }
  .content2 {
    width: 1176px;
    margin: 0 auto;

    .lianxiwomen {
      display: flex;
      text-align: left;
      margin-top: 50px;
      margin-bottom: 70px;

      .zaixianliuyan {
        margin-left: 129px;

        .btn {
          width: 403px;
          height: 64px;
          background-color: #fe992b;
          border-radius: 12px;
          color: #fff;
          font-size: 20px;
          line-height: 64px;
          text-align: center;
          margin-left: 100px;
          margin-top: 10px;
        }

        .text {
          color: #333;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .liuyan {
          display: flex;
          // align-items: center;

          margin-bottom: 16px;

          > p {
            width: 100px;
            font-size: 20px;
            color: #333;
            margin-top: 8px;
          }
        }

        .input {
          width: 403px;
          height: 44px;
          border-radius: 8px;
          border: none;
          padding: 15px;
          background-color: #f7f7f7;
          font-size: 16px;
          outline: none;
        }

        .input2 {
          width: 403px;
          border-radius: 8px;
          border: none;
          padding: 15px;
          background-color: #f7f7f7;
          font-size: 16px;
          outline: none;
        }
      }

      .erweima {
        display: flex;
        flex-direction: row;
        margin-top: 19px;

        > div:nth-child(1) {
          margin-right: 40PX;
          width: 158px;
          height: 158px;
        }

        > div:nth-child(2) {
          margin-left: 20PX;
          > div {
            margin-bottom: 20px;
            font-size: 20px;

          }
        }
      }
    }

    .div3 {
      width: 100%;
      text-align: left;
      margin-right: 40px;
      margin-top: 65px;

      .list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 140px;

        .card2 {
          width: 350px;
          height: 270px;
          border-radius: 12px;
          background-color: #fef8e9;
          padding: 26px 40px 40px 40px;
          text-align: center;

          div:nth-child(1) {
            color: #fe992b;
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 12px;
          }

          div:nth-child(2) {
            font-size: 18px;
            line-height: 36px;
          }
        }
      }

      .text3 {
        margin-top: 20px;
        color: #333;
        line-height: 36px;
        font-size: 18px;
        margin-bottom: 40px;
      }

      .text2 {
        width: 260px;
        color: #333;
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
        margin-top: 40px;
      }

      .text {
        color: rgba(153, 153, 153, 0.3);
        font-size: 28px;
        font-weight: 600;
      }

      .img {
        margin: 0 auto;
        > span {
          color: #333;
          font-size: 32px;
          font-weight: 600;
          margin-left: 28px;
        }

        > img {
          vertical-align: middle;
          margin-bottom: 25px;
        }
      }
    }

    .card {
      margin-top: 80px;
      width: 100%;
      height: 344px;
      background-color: #f7f7f7;
      padding-top: 70px;

      .div2 {
        display: flex;
        > div:nth-child(1) {
          border-left: 0;
        }

        > div {
          flex: 1;
          border-left: 2px solid #e3e3e2;
          height: 100px;

          > div:nth-child(1) {
            margin-bottom: 12px;
          }

          > div:nth-child(2) {
            font-size: 40px;
            font-weight: 600;
            color: #23ac94;
          }
        }
      }

      .title2 {
        color: #333;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 53px;
      }
    }

    .liuxuesheng {
      display: flex;
      .div {
        width: 690px;
        text-align: left;
        margin-right: 40px;

        .text3 {
          margin-top: 20px;
          color: #333;
          line-height: 36px;
          font-size: 18px;
        }

        .text2 {
          width: 260px;
          color: #333;
          font-size: 24px;
          font-weight: 600;
          line-height: 40px;
          margin-top: 40px;
        }

        .text {
          color: rgba(153, 153, 153, 0.3);
          font-size: 28px;
          font-weight: 600;
        }

        .img {
          margin: 0 auto;
          > span {
            color: #333;
            font-size: 32px;
            font-weight: 600;
            margin-left: 28px;
          }

          > img {
            vertical-align: middle;
            margin-bottom: 25px;
          }
        }
      }
    }

    .yizhanshi {
      display: flex;
      margin-bottom: 120px;
      align-items: center;

      .text {
        text-align: left;
        width: 690px;
        line-height: 36px;
        margin-left: 86px;
      }

      .img {
        position: relative;
        margin: 0 auto;
        .beijing {
          width: 346px;
          height: 256px;
          background-color: #fef8e9;
          position: absolute;
          bottom: -40px;
          right: -40px;
          z-index: -1;
        }
      }
    }

    .title {
      position: relative;
      height: 150px;
      //margin-top: 50px;

      .english-yellow {
        color: #fe992b;
        font-size: 44px;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 17px;
      }

      .servicetop {
        width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }

      .servicetop2 {
        width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        border-radius: 3px;
        margin: 0 auto;
      }

      .all-service {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;
      }
    }
  }
  .content-sm {
    width: 335PX;
    margin: 0 auto;
     .title {
      position: relative;
      height: 80PX;
      margin-top: 20PX;

      .english-yellow {
        color: #fe992b;
        font-size: 20PX;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 0;
      }

      .servicetop {
        font-size: 20PX;
        color: #333;
        font-weight: bold;
        padding: 10PX 0;
        margin: 0 auto;
      }
      .line2{
        width: 69PX;
        height: 3PX;
        background: #fe992b;
        border-radius: 3PX;
        margin: 0 auto;
      }
      .servicetop2 {
        width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
     .yizhanshi {
      display: flex;
      margin-bottom: 120px;
      text-align: left;
      flex-direction: column;
      height: 430PX;

      .text {
        text-align: left!important;
        width: 335PX;
        line-height: 24PX;
        // margin-left: 86px;
        font-size: 14PX;
        margin-top: 50PX;
      }

      .img {
        position: relative;
        margin: 0 auto;
        .beijing {
          width: 305PX;
          height: 197PX;
          background-color: #fef8e9;
          position: absolute;
          bottom: -29PX;
          right: 0;
          z-index: -1;
        }
      }
    }
     .liuxuesheng{
      display: flex;
      
      flex-direction: column;
      margin-top: 20PX;
      .liuxueshengimg {
        position: relative;
        margin-bottom: 50PX;
        margin-top: 16PX;
        text-align: left;
        .beijing {
          width: 305PX;
          height: 197PX;
          background-color: #fef8e9;
          position: absolute;
          bottom: -29PX;
          right: 0;
          z-index: -1;
        }
      }
      .div {
        width: 335PX;
        text-align: left;
        margin-right: 40PX;

        .text3 {
          margin-top: 10PX;
          color: #333;
          line-height: 24PX;
          font-size: 14PX;
        }

        .text2 {
          width: 165PX;
          color: #333;
          font-size: 16PX;
          font-weight: 600;
          line-height: 30PX;
          margin-top: 16PX;
        }

        .text {
          color: rgba(153, 153, 153, 0.3);
          font-size: 16PX;
          font-weight: 600;
        }

        .img {
          display: flex;
          flex-direction: row;
          align-items:center;
          > span {
            color: #333;
            font-size: 20PX;
            font-weight: 600;
            margin-left: 12PX;
          }

          > img {
            vertical-align: middle;
            margin-bottom: 25px;
          }
        }
      }
    }
    
    
  }
.lianxiwomen-sm {
      display: flex;
      text-align: left;
      margin-top: 50PX;
      margin-bottom: 24PX;
        flex-direction: column;

      .zaixianliuyan {
        // margin-left: 129PX;
        .btn {
          width: 335PX;
          height: 48PX;
          background-color: #fe992b;
          border-radius: 12PX;
          color: #fff;
          font-size: 16PX;
          line-height: 48PX;
          text-align: center;
          margin-top: 24PX;
        }

        .text {
          color: #333;
          font-size: 14PX;
          font-weight: 600;
          margin-bottom: 20PX;
        }

        .liuyan {
          display: flex;
          margin-bottom: 16PX;

          > p {
            width: 100PX;
            font-size: 14PX;
            color: #333;
            margin-top: 8PX;
          }
        }

        .input {
          width: 265PX;
          height: 44PX;
          border-radius: 8PX;
          border: none;
          padding: 15px;
          background-color: #f7f7f7;
          font-size: 14PX;
          outline: none;
        }

        .input2 {
          width:265PX;
          border-radius: 8PX;
          border: none;
          padding: 15PX;
          background-color: #f7f7f7;
          font-size: 14PX;
          outline: none;
        }
      }

      .erweima {
        display: flex;
        flex-direction: row;
        margin-top: 19PX;

        > div:nth-child(1) {
          margin-right: 10PX;
          height: 120PX;
        }

        > div:nth-child(2) {
          > div {
            margin-bottom: 12PX;
            font-size: 12PX;
          }
        }
      }
    }
}
</style>
